// Generated by Framer (2372734)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Image from "./GcII1dPB0";
const ImageFonts = getFonts(Image);

const cycleOrder = ["IoUUTkY1g"];

const variantClassNames = {IoUUTkY1g: "framer-v-12x1lls"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IoUUTkY1g", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-tPWBy", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-12x1lls", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IoUUTkY1g"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-1hjsfv1-container"} layoutDependency={layoutDependency} layoutId={"HtbeDVd18-container"}><Image height={"100%"} id={"HtbeDVd18"} layoutId={"HtbeDVd18"} style={{width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-tPWBy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tPWBy .framer-1ydjali { display: block; }", ".framer-tPWBy .framer-12x1lls { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 426px; }", ".framer-tPWBy .framer-1hjsfv1-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tPWBy .framer-12x1lls { gap: 0px; } .framer-tPWBy .framer-12x1lls > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-tPWBy .framer-12x1lls > :first-child { margin-left: 0px; } .framer-tPWBy .framer-12x1lls > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 558
 * @framerIntrinsicWidth 426
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 */
const FramerxrYAA2mxE: React.ComponentType<Props> = withCSS(Component, css, "framer-tPWBy") as typeof Component;
export default FramerxrYAA2mxE;

FramerxrYAA2mxE.displayName = "Gallery Image";

FramerxrYAA2mxE.defaultProps = {height: 558, width: 426};

addFonts(FramerxrYAA2mxE, [...ImageFonts])